import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import Cookies from 'universal-cookie';

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkPopupBlocker: false
    };
  }

  render() {
    let opened;
    const onSuccess = (data, actions) => {
      const clase = this;
      // Capture the funds from the transaction
      return actions.order.capture().then(function (details) {
        // Show a success message to your buyer
        console.log("Transaction completed by " + details.payer.name.given_name);
        clase.props.onSuccess(data.orderID);
      });
    };

    const onCancel = (data) => {
      this.props.onCancel(data);
    };

    const onError = (err) => {
      console.error("Paypal error", err);
      this.props.onError(err);
    };

    const onPaypalCheckoutMessage = (event) => {
      console.log("onPaypalCheckoutMessage", event.origin, event.data);
      if (process.env.REACT_APP_PAYPAL_REDIRECT_URL.indexOf(event.origin) !== -1 && opened && !opened.closed) {
        let procesed = false;
        if (event.data.status === "success") {
          procesed = true;
          this.props.onSuccess(event.data.data, {});
        } else if (event.data.status === "cancel") {
          procesed = true;
          this.props.onCancel(event.data.data);
        } else if (event.data.status === "error") {
          procesed = true;
          this.props.onError(event.data.data);
        }

        if (procesed) {
          opened.close();
          opened = null;
          window.removeEventListener("message", onPaypalCheckoutMessage);
        }
      }
    }

    const onPaypalCheckout = () => {
      const cookies = new Cookies();
      const params = JSON.stringify({
        depositState: {
          ...this.props.depositState,
          paymentMethods: [],
          plans: []
        }
      })
      console.log("params", params);
      opened = window.open(`${process.env.REACT_APP_PAYPAL_REDIRECT_URL}?code=${window.btoa(params)}&lan=${cookies.get("lan") || ""}`, "paypalcheckout");
      if(!opened || opened.closed || typeof opened.closed=='undefined') { 
          //POPUP BLOCKED
          this.setState({checkPopupBlocker: true});
      }
      // listen for message from paypal
      window.addEventListener("message", onPaypalCheckoutMessage);
    };

    let currency = "USD"; // or you can set this value from your props or state
    let total = this.props.total; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout

    let style = {
      label: "pay",
      size: "responsive", // small | medium | large | responsive
      shape: "rect", // pill | rect
      color: "black", // gold | blue | silver | black
      tagline: 0,
    };

    return process.env.REACT_APP_PAYPAL_REDIRECT_URL ? (
        <>
          <button
            onClick={onPaypalCheckout}
            style={{
              backgroundColor: "rgb(0, 0, 0)",
              color: "rgb(255, 255, 255)",
              height: "55px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "18px",
              lineHeight: "55px"
            }}>
            Buy With Paypal
          </button>
          {this.state.checkPopupBlocker && <div style={{color: "red"}}>Please disable your popup blocker to continue</div>}
        </>
      ) : (  
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_ID }}>
          <PayPalButtons 
            style={style} 
            onError={onError}
            onApprove={onSuccess}
            onCancel={onCancel}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: total,
                    },
                  },
                ],
              });
            }}
            forceReRender={[total, currency]}
            />
        </PayPalScriptProvider>
      );
  }
}

export default PaypalButton;
