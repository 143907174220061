import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import StripePaymentElement from "./components/StripePaymentElement";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const MobileCheckoutApp = () => {
  const [clientSecret, setClientSecret] = useState(null)
  const [textos, setTextos] = useState({})
  const containerRef = useRef(null);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "night",
      variables: {
        colorText: "#fff",
        colorPrimary: '#958063',
        fontSizeBase: '16px',
        fontWeightNormal: '900',
      },
      fontFamily: 'Open Sans, Arial, sans-serif',
      rules: {
        '.Input::placeholder': {
          color: '#958063',
        },
        '.Dropdown': {
          color: '#fff',
        },
        '.DropdownItem': {
          color: '#fff',
        },
        '.Label': {
          color: '#958063',
        }
      }
    },
  };

  const urlGlobal = process.env.REACT_APP_BACKEND_ENDPOINT;
  const postMessage = (data, targetOrigin) => {
    const fn = window.postMessageNew ? window.postMessageNew : window.ReactNativeWebView ? window.ReactNativeWebView.postMessage : window.postMessage;
    try {
      fn(JSON.stringify(data), targetOrigin);
    } catch (error) {
      window.postMessage(JSON.stringify(data), targetOrigin);  
    }
  }

  //const logapp = (message) => postMessage({ type: 'log', message }, "*");
  //console.log = (message) => postMessage({type: 'log', message}, "*");

  useEffect(() => {
    // subscribe for recive parent data messages
    window.addEventListener("messageRN", onParentMessage)
    window.addEventListener("message", onParentMessage)

    postMessage({ type: 'loaded' });

    return () => {
      // unsubscribe for recive parent data messages
      window.removeEventListener("messageRN", onParentMessage)
      window.removeEventListener("message", onParentMessage)
    }
  }, [onParentMessage])

  useEffect(() => {
    if (!containerRef.current) return

    const interval = setInterval(() => {
      postMessage({ type: 'height', height: containerRef.current.scrollHeight });
    }, 500);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [containerRef])

  const onSuccess = (paymentId) => {
    // send message to parent
    postMessage({ type: 'payment', success: true, paymentId, err: null }, "*");
  }

  const onError = (err) => {
    // send message to parent
    postMessage({ type: 'payment', success: false, paymentId: null, err }, "*");
  }

  const onReady = () => {
    postMessage({ type: 'ready' });
  }

  function onParentMessage(event) {
    event = event.detail || event;
    if (event.origin === "") {
      // Events from app
      const data = event && event.data && JSON.parse(event.data);
      if (data.type === 'data') {
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        }
        if (data.textos) {
          setTextos(data.textos);
        }
      }
    }
  }

  return (<div className="h-100 w-100 pb-4 text-light text-center d-flex justify-content-center" ref={containerRef}>
    {clientSecret && (
      <Elements stripe={stripePromise} options={options} >
        <StripePaymentElement
          urlGlobal={urlGlobal}
          textosShow={textos}
          onSuccess={onSuccess}
          isSetup={false}
          onReady={onReady}
          onError={onError} />
      </Elements>
    )}
  </div>
  );
}

export default MobileCheckoutApp;